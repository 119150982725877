import { render, staticRenderFns } from "./ProductDetails.vue?vue&type=template&id=46a969cd"
import script from "./ProductDetails.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductDetails.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductFeatures: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/sections/productSection/ProductFeatures.vue').default,UiTab: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiTab.vue').default,ProductUserHelp: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/sections/productSection/ProductUserHelp.vue').default,ProductSoftware: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/sections/productSection/ProductSoftware.vue').default,UiTabs: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiTabs.vue').default})
