import { render, staticRenderFns } from "./AppSearch.vue?vue&type=template&id=14ff1798"
import script from "./AppSearch.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppSearch.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiIcon.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiButton.vue').default,UiSearchForm: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiSearchForm.vue').default,UiSearchFormPopularQueries: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiSearchFormPopularQueries.vue').default})
